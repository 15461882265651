import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import frJson from "./locale/fr.json";
import enJson from "./locale/en.json";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: enJson,
    },
    fr: {
      translation: frJson,
    },
  },
  lng: "fr", // Default language
  fallbackLng: "en",
  interpolation: {
    escapeValue: false, // React already escapes values
  },
});

export default i18n;
