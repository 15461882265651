import React, { Component } from "react";
import "../styles/navbar.css";
import { useMe } from "../services/api/users/me";
import Radium from "radium";
function Navbar() {
  const { data: me } = useMe();
  console.log("MEEE", me);

  return (
    <div className="navbar">
      <div className="logo">
        <img
          src="assets/logo/logo-navbar.png"
          alt="Logo de Flit Finance"
          className="img-logo-navbar"
        />
      </div>
      <div className="menu">
        <div className="onglet">
          <a href="/videos">Nos vidéos</a>
        </div>
        <div className="onglet">Nos simulateurs</div>
        <a href="/profil" className="button">
          <img src="assets/icons/profil.png" className="icon" />
          <div className="">
            {me?.firstname && me?.lastname
              ? `${me?.firstname} ${me?.lastname}`
              : "Votre compte"}
          </div>
        </a>
      </div>
    </div>
  );
}

export default Radium(Navbar);
