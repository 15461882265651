import Radium from "radium";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "../styles/profil";

import FlatList from "flatlist-react";

import {
  useDeleteDocuments,
  useDocuments,
  useDocumentsTypes,
  useUpdateDocument,
  useUploadDocument,
} from "../services/api/documents/use-documents";
import { useMe } from "../services/api/users/me";
import { FileUploader } from "react-drag-drop-files";
import Navbar from "../components/Navbar";
import { logout } from "../services/helpers/auth/logout";
import { useUpdateUser } from "../services/api/users/use-users";
import { useTranslation } from "react-i18next";

function Profil() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const fileInput = useRef();
  const [file, setFile] = useState(null);
  const [typeId, setTypeId] = useState(null);
  const fileTypes = ["JPG", "PNG", "JPEG", "PDF"];
  const { data: me } = useMe();
  const [inputs, setInputs] = useState({
    firstname: me?.firstname || "",
    lastname: me?.lastname || "",
    email: me?.email || "",
    phone: me?.phone || "",
    address1: me?.address1 || "",
    city: me?.city || "",
    zip_code: me?.zip_code || "",
  });
  const { data: documents } = useDocuments();
  const { data: documentsTypes } = useDocumentsTypes();
  const { mutateAsync: deleteDocumentAsync } = useDeleteDocuments();
  const { mutateAsync: addDocumentAsync, isSuccess: uploadSuccess } =
    useUploadDocument();
  const { mutateAsync: updateDocumentAsync } = useUpdateDocument(
    documents && documents[documents?.length - 1]?.id
  );
  const { mutateAsync: updateUser } = useUpdateUser(me && me?.id);

  const deleteDocument = (e) => {
    deleteDocumentAsync(e);
  };
  const handleChange = (file) => {
    setFile(file);
    const data = new FormData();
    data.append("document[path]", file);
    addDocumentAsync(data);
  };
  const handleInputChange = (event) => {
    setInputs({
      ...inputs,
      [event.target.name]: event.target.value,
    });
  };
  const handleInputBlur = (event) => {
    const nonEmptyInputs = Object.fromEntries(
      Object.entries(inputs).filter(([key, value]) => value !== "")
    );
    updateUser(nonEmptyInputs);
  };

  const onLogout = () => {
    logout();
    navigate("/login");
  };

  useEffect(() => {
    if (typeId) {
      updateDocumentAsync({
        type_id: typeId,
      });
    }
  }, [typeId]);

  const updateAvatar = () => {
    fileInput.current.click();
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const data = new FormData();
    data.append("user[avatar]", file);
    updateUser(data);
  };

  const renderFile = (document, index) => {
    return (
      <div key={index}>
        <div style={styles.card} key={index}>
          <img src="assets/icons/file.png" style={styles.iconFile} alt="file" />
          <button
            onClick={() => deleteDocument(document?.id)}
            style={styles.containerIconDelete}
          >
            <img
              src="assets/icons/delete.png"
              alt="icone corbeille"
              style={styles.iconDelete}
            />
          </button>
        </div>
        <div style={styles.textItem}>{document?.fileName || "Aucun nom"}</div>
        {uploadSuccess && index == documents[documents?.length - 1].id && (
          <div style={styles.dropdown}>
            <div style={styles.containerInput}>
              <select
                onChange={(e) => setTypeId(e.target.value)}
                style={styles.input}
              >
                {documentsTypes?.map((type) => (
                  <option key={type.id} value={type.id}>
                    {type.nom}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div id="profil">
      <Navbar />
      <div style={styles.bg}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={styles.container}>
            <div style={styles.profil}>
              <div style={styles.headerProfil}>
                <div style={styles.title}>Profil</div>
                <div
                  style={[
                    styles.containerImageProfil,
                    {
                      backgroundImage: `url(${me?.avatar_url})`,
                    },
                  ]}
                >
                  <button
                    onClick={updateAvatar}
                    style={styles.containerIconEdit}
                  >
                    <img
                      src="assets/icons/edit.png"
                      alt="a single checked balloon"
                      style={styles.iconEdit}
                    />
                    <input
                      type="file"
                      ref={fileInput}
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                    />
                  </button>
                </div>
              </div>

              <div style={styles.contentProfil}>
                <div style={styles.bloc1}>
                  <div className="row">
                    <div className="col-6">
                      <div className="container-input">
                        <input
                          type="text"
                          placeholder={me?.firstname || "Prénom"}
                          value={inputs?.firstname}
                          onChange={handleInputChange}
                          name="firstname"
                          onBlur={handleInputBlur}
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="container-input">
                        <input
                          type="text"
                          placeholder={me?.lastname || "Nom"}
                          value={inputs?.lastname}
                          onChange={handleInputChange}
                          name="lastname"
                          onBlur={handleInputBlur}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="container-input">
                        <input
                          type="text"
                          placeholder={me?.email || "Adresse mail"}
                          value={inputs?.email}
                          onChange={handleInputChange}
                          name="email"
                          onBlur={handleInputBlur}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <div className="container-input">
                        <input type="text" placeholder="mot de passe" />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="container-input">
                        <input
                          type="text"
                          placeholder={me?.phone || "numéro de téléphone"}
                          value={inputs?.phone}
                          onChange={handleInputChange}
                          name="phone"
                          onBlur={handleInputBlur}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="container-input">
                        <input
                          type="text"
                          placeholder={me?.address1 || "Adresse Postale"}
                          value={inputs?.address1}
                          onChange={handleInputChange}
                          name="address1"
                          onBlur={handleInputBlur}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <div className="container-input">
                        <input
                          type="text"
                          placeholder={me?.city || "Ville/Commune"}
                          value={inputs?.city}
                          onChange={handleInputChange}
                          name="city"
                          onBlur={handleInputBlur}
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="container-input">
                        <input
                          type="text"
                          placeholder={me?.zip_code || "Code postal"}
                          value={inputs?.zip_code}
                          onChange={handleInputChange}
                          name="zip_code"
                          onBlur={handleInputBlur}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div style={styles.bloc2}>
                  <div style={styles.contentFlatlist}>
                    <div>
                      {/* <div style={{ ...styles.card, ...styles.firstCard }}>
                        <img
                          src="assets/icons/add-file.png"
                          style={styles.iconFile}
                          alt="file"
                        /> */}
                      <FileUploader
                        handleChange={handleChange}
                        name={file}
                        types={fileTypes}
                      />
                      {/* <div style={styles.textAddFile}>
                          Ajoutez un nouveau document
                        </div>
                      </div> */}
                    </div>
                    <FlatList
                      list={documents}
                      renderItem={renderFile}
                      renderWhenEmpty={() => <div>{t("emptyList")}</div>}
                    />
                  </div>
                </div>

                <div style={styles.bloc3}>
                  <div className="row">
                    <div className="col-12">
                      <input
                        style={styles.LogoutButton}
                        type="submit"
                        value="Deconnexion"
                        onClick={onLogout}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Radium(Profil);
