import { useQuery } from "react-query";
import useApi from "../axios";

export const useMe = (trigger = true) => {
  const { get } = useApi();

  return useQuery({
    enabled: trigger,
    queryFn: async () => await get("/users/current"),
    queryKey: ["user"],
    onSuccess: (data) => {
      // alert(JSON.stringify(data));
    }
  });
};
